<template>
  <div
    class="gamelist-wrap"
    ref="gamelist"
    :class="{ 'scroll' : scrollable }"
  >
    <slot></slot>
  </div>
</template>

<script>
import { getApp } from '@/assets/js/mixins/common/GetApp'
import { detectDevice } from '@/assets/js/mixins/base/DetectDevice'
import { elementExists } from '@/assets/js/mixins/base/ElementExists'

export default {
  name: 'gamelist',

  props: {
    scrollable: {
      type: [String, Boolean],
      default: false
    },

    dataLoading: {
      type: [Boolean],
      default: true
    }
  },

  provide () {
    const gamelistConfig = this.gamelistConfig
    return { gamelistConfig }
  },

  data () {
    return {
      gamelistConfig: {
        gamelist_w: 0,
        bodyGamelist_w: 0,
        body_w: 0,
        col_name: null,
        asc: false,
        scrollable: null
      }
    }
  },

  mounted () {
    this.setGamelistHeight()
    this.$nextTick(() => {
      this.gamelistConfig.scrollable = this.scrollable

      window.addEventListener('resize', this.getGamelistWidth)
      this.getGamelistWidth()
    })
  },

  beforeDestroy () {
    window.removeEventListener('resize', this.getGamelistWidth)
  },

  methods: {
    setGamelistHeight () {
      let footer = document.querySelector('.footer')
      let masterhead = document.querySelector('.masterhead')

      if (masterhead) {
        if (this.$el.parentNode && this.device_height > 799 && this.device_width > 767) {
          this.gamelistConfig.gamelist_h = this.device_height - footer.offsetHeight - masterhead.offsetHeight
        } else {
          this.gamelistConfig.gamelist_h = this.device_height - masterhead.offsetHeight - footer.offsetHeight
        }
      }
    },

    getGamelistWidth () {
      this.gamelistConfig.body_w = this.$refs.gamelist.clientWidth
    }
  }
}
</script>
